export enum HeaderConstants {
  ACCOUNT_ID = 'x-account-id',
}

export const DEFAULT_FORM = '<DefaultForm />';
export const DEFAULT_PAGE = 'home';
export const NAVIGATION_BAR_HEIGHT = '4rem';

export const DEFAULT_PAGE_OPTIONS = [10, 25, 50];

export const SUCCESSFUL_REGISTRATION_TITLE = 'Registered Successfully';
export const DEFAULT_SUCCESSFUL_REGISTRATION_MESSAGE =
  'You have successfully registered for this event.';

export enum CommonStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EventRegistrantRoles {
  EVENT_SUPPORT = 'Event Support',
  WALL_PARTICIPANT = 'Wall Participant',
  WALL_PRESENTER = 'Wall Presenter',
  OFF_WALL_PARTICIPANT = 'Off-wall Participant',
  OFF_WALL_ADMIN = 'Off-wall Admin',
  OBSERVER = 'Observer',
  VENUE_ON_WALL = 'Venue On-wall',
  VENUE_OFF_WALL = 'Venue Off-wall',
}

export const SPEAKER_ROLES = [
  EventRegistrantRoles.WALL_PRESENTER,
  EventRegistrantRoles.EVENT_SUPPORT,
  EventRegistrantRoles.OFF_WALL_ADMIN,
  EventRegistrantRoles.VENUE_ON_WALL,
  EventRegistrantRoles.VENUE_OFF_WALL,
];

export const PARTICIPANT_ROLES = [
  EventRegistrantRoles.WALL_PARTICIPANT,
  EventRegistrantRoles.OFF_WALL_PARTICIPANT,
  EventRegistrantRoles.OBSERVER,
];

export enum PreEventLoginTypes {
  ALWAYS = 'ALWAYS',
  DAY_OF = 'DAY_OF',
  CUSTOM = 'CUSTOM',
}

export enum PasswordRequirements {
  UNIQUE_PER_PARTICIPANT = 'UNIQUE_PER_PARTICIPANT',
  UNIQUE_PER_SITE = 'UNIQUE_PER_SITE',
  NONE = 'NONE',
}
